import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/4cd8a442/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "zoetrope-lantern"
    }}>{`Zoetrope Lantern`}</h1>
    <p>{`Every year in Stony Stratford we hold a lantern parade where hundreds of `}<del parentName="p">{`children`}</del>{` parents make
lanterns from withies, tissue paper and a lot of PVA glue. The lanterns are illuminated using a
candle or some fairy lights and then we process down the High Street, meeting at the Market Square
for the turning on of the Christmas lights.`}</p>
    <p>{`We have made lanterns for a few years now ranging from pyramids to tortoises. This year I suggested
that we try to make an animated lantern!`}</p>
    <p>{`I thought we could make something like a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Zoetrope"
      }}>{`zoetrope`}</a>{`. A
zoetrope consists of a cylinder with slits to look through on the outside and images on the inside.
Look through one of the slits so you can see the image on the opposite side. Now spin the zoetrope
and you will see a sequence of images which form an animation.`}</p>
    <p>{`Now an actual zoetrope lantern would be fairly useless, from a distance you would only see the
slits not the picture on the inside. So our lantern was a bit of an interpretation. We needed to
show a sequence of images, the new image replacing the old one in the `}<em parentName="p">{`same location`}</em>{`.`}</p>
    <p>{`Our lantern would have the following properties:`}</p>
    <ol>
      <li parentName="ol">{`A light source which pointed in one direction`}</li>
      <li parentName="ol">{`The ability for the lantern to spin around the light source`}</li>
      <li parentName="ol">{`12 panels that would be lit up one at a time`}</li>
    </ol>
    <p>{`I realised this construction would need a level of precision that withies cannot provide so I built
the frame for the lanterns from mountboard (and cardboard when I ran out of mountboard). I made 12
side panels and then 3 ribs which the side panels slotted into.`}</p>
    <p><img alt="Construction using slots" src={require("../assets/images/zoetrope/001-slots.jpg")} /></p>
    <p>{`For a light source I decided to use some old kitchen overflow pipe and a cheap pack of LEDs. I
drilled holes in a straight line down the pipe and inserted the LEDs.`}</p>
    <p>{`Now imagine this construction turning around the pole. Images are lit up when the lights are in that
sector. This means you will see each picture move a bit before the next picture appears. To reduce
this we need to make the slot narrower.`}</p>
    <p><img alt="The narrow slits for light to come through from the outside" src={require("../assets/images/zoetrope/002-slits.jpg")} /></p>
    <p><img alt="The narrow slits for light to come through from the inside" src={require("../assets/images/zoetrope/003-slits-inside.jpg")} /></p>
    <p>{`Then the whole construction was put on the pole.`}</p>
    <p><img alt="On the pole" src={require("../assets/images/zoetrope/004-pole.jpg")} /></p>
    <p>{`I cut out tissue paper rectangles of to just overlap each sector a little bit. This would become
the background for our animation.`}</p>
    <p><img alt="Papered and ready for images" src={require("../assets/images/zoetrope/005-papered.jpg")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      